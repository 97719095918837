import React from 'react';

import { Layout } from 'antd';

import SideMenu from './SideMenu';
import Footer from './Footer';
import Header from './Header';

const { Content } = Layout;

const LayoutWithRoute = ({ children }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* <SideMenu /> */}
      <Layout className="site-layout" style={{ background: 'white' }}>
        <Header />
        <Content
          style={{
            padding: '40px 0px',
            maxWidth: 960,
            margin: 'auto',
            width: '100%',
          }}
        >
          {children}
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

export default LayoutWithRoute;
