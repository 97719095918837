import React from 'react';
import { Layout, Menu, Dropdown, Avatar } from 'antd';
import { useFuro } from 'furo-react';
import {
  UserOutlined,
  ImportOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';

const Logo = () => {
  return <></>;
};

const Header = () => {
  const { logout, user } = useFuro();
  const navigate = useNavigate();

  const menu = [
    {
      key: '1',
      label: 'API Docs',
      onClick: () => window.open('https://docs.furo.one'),
      style: { marginLeft: 'auto' },
    },
    {
      key: '2',
      label: (
        <Avatar
          size={40}
          icon={<UserOutlined />}
          src={user?.profile_url}
          style={{ backgroundColor: '#ccc' }}
        />
      ),
      children: [
        {
          key: 'sub-1',
          icon: <UserOutlined />,
          label: user ? user.email : 'try@test.com',
        },
        {
          key: 'sub-2',
          icon: <SettingOutlined />,
          label: '설정',
          onClick: () => navigate('/settings'),
        },
        {
          key: 'sub-3',
          icon: <ImportOutlined />,
          label: '로그아웃',
          onClick: logout,
        },
      ],
      theme: 'light',
    },
  ];

  // const menu = (
  //   <Menu>
  //     <Menu.Item key="1" icon={<UserOutlined />}>
  //       {user ? user.email : 'try@test.com'}
  //     </Menu.Item>
  //     <Menu.Divider />
  //     <Menu.Item
  //       key="2"
  //       icon={<SettingOutlined />}
  //       onClick={() => navigate('/settings')}
  //     >
  //       설정
  //     </Menu.Item>
  //     <Menu.Divider />
  //     <Menu.Item key="3" icon={<ImportOutlined />} onClick={logout}>
  //       {/* Logout */}
  //       로그아웃
  //     </Menu.Item>
  //   </Menu>
  // );

  return (
    <Layout.Header style={{ padding: 0 }}>
      <div style={styles.header}>
        <Link to="/projects">
          <div style={styles.logo}></div>
        </Link>
        <Menu theme="dark" mode="horizontal" items={menu} selectable={false} />
        {/* <Menu theme="dark" mode="horizontal" selectable={false}>
          <Menu.Item
            key="1"
            style={{ marginLeft: 'auto' }}
            onClick={() => window.open('https://docs.furo.one')}
          >
            문서
          </Menu.Item>
          <Dropdown overlay={menu} placement="bottomRight">
            <Menu.Item key="2">
              <Avatar
                size={40}
                icon={<UserOutlined />}
                src={user?.profile_url}
              />
            </Menu.Item>
          </Dropdown>
        </Menu> */}
      </div>
    </Layout.Header>
  );
};

const styles = {
  header: {
    padding: '0px 20px',
    maxWidth: 960,
    margin: 'auto',
  },
  logo: {
    float: 'left',
    width: '120px',
    height: '30px',
    margin: '17px 24px 17px -10px',
    backgroundImage: `url('/furo_logo_w.png')`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    // backgroundColor: 'green',
  },
};

export default Header;
