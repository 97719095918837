import React, { useEffect } from 'react';
import { Modal, Typography, List } from 'antd';
import { CloseOutlined, CalendarOutlined } from '@ant-design/icons';
import { SALES_CONTACT_URI } from '../data';
import mixpanel from '../libs/mixpanel';
import { useFuro } from 'furo-react';
import { Button } from '.';

const { Title, Text } = Typography;

const ContactModal = ({ isOpen, setOpen }) => {
  const { user } = useFuro();
  useEffect(() => {
    if (isOpen) {
      mixpanel.track('Contact Modal Open', {
        UID: user.uid,
        distinct_id: user.uid,
      });
    }
  }, [isOpen]);
  return (
    <Modal
      centered
      open={isOpen}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={null}
      bodyStyle={styles.card}
      closeIcon={<CloseOutlined style={{ color: '#ccc' }} />}
      width={'90%'}
      style={{ maxWidth: 800 }}
    >
      <img src="/images/thunder.png" style={styles.bg} />
      <div>
        <p style={styles.subtitle}>Furo 플랜 무제한 사용 이벤트</p>
        <Title style={styles.title}>
          Furo의 모든 기능을
          <br /> 더 마음껏 사용하세요!
        </Title>
        <ul style={{ padding: '0px 24px', color: '#616161' }}>
          <li>50K MAU 사용량</li>
          <li>MAU, DAU, 전환율 애널리틱스</li>
          <li>향상된 데이터 프라이버시 및 보안</li>
        </ul>
        <div style={{ marginTop: 20 }}>
          <Button
            type="primary"
            onClick={() => {
              mixpanel.track('Sales Contact Button Click', {
                UID: user.uid,
                distinct_id: user.uid,
              });
              window.open(SALES_CONTACT_URI);
            }}
            style={styles.btn}
          >
            신청하기
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const styles = {
  card: {
    backgroundImage: 'url(/images/bg.png)',
    backgroundRepeat: 'round repeat',
    backgroundPosition: 'center',
    backgroundSize: '100%',
    height: 500,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 'clamp(2vh, 4vh, 2rem)',
    lineHeight: 1.4,
    color: 'black',
    fontWeight: 700,
    zIndex: 5,
  },
  subtitle: { fontWeight: 600, color: '#CB6266', lineHeight: 0.5 },
  bg: {
    width: '40%',
    position: 'absolute',
    right: 40,
    bottom: 40,
  },
  btn: {
    width: 200,
    fontWeight: 600,
    height: 50,
  },
};

export default ContactModal;
