import platforms from './platforms';
import settings from './settings';
import pricing from './pricing';
import presets from './presets';

// const LevelTitle = {
//     service: '서비스에 집중하세요.',
//     login: '로그인은 저희가 할게요.'
// }
const loginMe = '저희가 만들어 드릴게요.';
const developers = {
  we: 'We’re developers. 💻',
  p1: `개발자를 위해 개발자가 만들었어요. '모든 서비스의 필수
    요소인 로그인, 회원가입, 비밀번호 찾기, 소셜 로그인 등, 왜
    이런걸 한 번에 제공해주는 서비스는 없을까?' 이 생각에
    만들었어요. 알아요, 여러분이 회원관리 서비스 만들 수 있다는거.
    하지만 개발자라면 가장 효율적인 방법을 선택해야 하잖아요?`,
  p2: `Furo(퓨로)는 20분 만에 여러분의 프로젝트에 회원관리 시스템을
    적용할 수 있도록 도와주는 서비스에요. 대시보드에서 프로젝트
    생성하고 API키 받아서 넣으면 돼요. 소셜로그인도 API 키만
    붙여넣기 해주세요. 로그인 화면에 바로 적용되는걸 볼 수 있어요.
    전화번호 인증, 이메일 인증, CAPTCHA 기능까지 10초면 설정할 수
    있어요. 다양한 로그인 화면 디자인도 제공할거에요.`,
};
const CTA = {
  t1: 'Let’s get started',
  t2: `20분 만에 프로젝트에 회원관리 기능을 추가하세요. 
    팀과 함께 Furo 대시보드에서 현황을 공유하세요.`,
};
const JOIN = {
  p1: 'Furo에 관심 있으신가요?',
  p2: `Furo는 하나의 커뮤니티로써, 여러분의 참여를 기다리고 있습니다. 각자의 개발 배경을 바탕으로 오픈소스 커뮤니티에 컨트리뷰터로써 참여하고 보상받으세요. 추후 앰배서더 지원 프로그램도 준비할 예정입니다.`,
  p3: '최신 소식이 궁금하신가요?',
};

const SALES_CONTACT_URI = 'https://whattime.co.kr/furo/onboarding';

const PRIMARY_COLOR = '#1677ff';

export {
  loginMe,
  developers,
  CTA,
  JOIN,
  platforms,
  settings,
  SALES_CONTACT_URI,
  pricing,
  PRIMARY_COLOR,
  presets,
};
