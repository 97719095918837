import { Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const { Text } = Typography;

const Loading = ({ style }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
        ...style,
      }}
    >
      <Spin size="large" />
      <Text type="secondary" style={{ marginTop: 6 }}>
        Loading...
      </Text>
    </div>
  );
};

const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

export const InlineLoading = () => {
  return <Spin indicator={antIcon} style={{ marginRight: 7 }} />;
};

export default Loading;
