import React, { useState } from 'react';

import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  DashboardOutlined,
  TeamOutlined,
  ProjectOutlined,
} from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const { Sider } = Layout;

const SideMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  let location = useLocation();
  const navigate = useNavigate();

  const onCollapse = (collapsed) => setCollapsed(collapsed);

  const menu = [
    {
      key: '1',
      icon: <HomeOutlined />,
      label: 'Home',
      onClick: () => navigate('/'),
    },
    {
      key: '2',
      icon: <ProjectOutlined />,
      label: 'Projects',
      onClick: () => navigate('/projects'),
    },
    {
      key: '3',
      icon: <DashboardOutlined />,
      label: 'Dashboard',
      onClick: () => navigate('/dashboard'),
    },
  ];
  return (
    <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
      <div className="logo" style={styles.logo}>
        FURO
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        items={menu}
      />
      {/* <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
      >
        <Menu.Item key="/">
          <HomeOutlined />
          <span>Home</span>
          <Link to="/" />
        </Menu.Item>

        <Menu.Item key="/projects">
          <ProjectOutlined />
          <span>Projects</span>
          <Link to="/projects" />
        </Menu.Item>

        <Menu.Item key="/dashboard">
          <DashboardOutlined />
          <span>Dashboard</span>
          <Link to="/dashboard" />
        </Menu.Item>
      </Menu> */}
    </Sider>
  );
};

const styles = {
  logo: {
    margin: '11px',
    color: 'white',
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 600,
  },
};

export default SideMenu;
