import React, { useState } from 'react';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { PRIMARY_COLOR } from '../data';

const CopyButton = ({ text, _onClick, inset = true }) => {
  const [copy, setCopy] = useState(true);

  return (
    <Tooltip placement="top" title={copy ? 'Copy' : 'Copied'}>
      {copy ? (
        <CopyOutlined
          style={Object.assign(
            {
              color: PRIMARY_COLOR,
            },
            inset && styles.inset,
          )}
          onClick={() => {
            setCopy(!copy);
            _onClick();
            setInterval(() => {
              setCopy(true);
            }, 3000);
          }}
        />
      ) : (
        <CheckOutlined
          style={Object.assign(
            {
              color: '#51c11a',
            },
            inset && styles.inset,
          )}
        />
      )}
    </Tooltip>
  );
};

const styles = {
  icon: {},
  inset: {
    position: 'absolute',
    right: 12,
    top: 10,
  },
};

export default CopyButton;
