const features = {
  common: [
    [
      '로그인 방식',
      true,
      true,
      true,
      true,
      '이메일, Magic Link 방식뿐만 아니라 Google, Kakao 등 다양한 로그인 방식을 제공합니다. 서비스에 최적화된 로그인 방식을 도입해 보세요.',
      true,
    ],
    [
      '무제한 프로젝트',
      true,
      true,
      true,
      true,
      '프로젝트 생성에는 제한이 없습니다. 아이디어와 비즈니스에 맞는 워크스페이스를 만들어 즉시 로그인 서비스를 연동해 보세요.',
      true,
    ],
    [
      '트래픽 관리',
      true,
      true,
      true,
      true,
      '서비스의 일시적인 트래픽 증가로 인한 서버 과부하로부터 프로젝트를 안전하게 관리하세요.',
      true,
    ],

    [
      '로그인 이력 관리',
      false,
      false,
      true,
      true,
      '사용자 로그인 이력을 추적, 관리할 수 있습니다.',
      true,
    ],
  ],
  custom: [
    [
      '최신 개인정보보호법 관리',
      true,
      true,
      true,
      true,
      '필요한 항목들을 설정하여 개인정보 처리방침을 간편하게 자동 생성해보세요.',
      true,
    ],
    [
      '웹훅 연동',
      false,
      false,
      true,
      true,
      'Slack을 연동하여 프로젝트의 다양한 알림을 받아보실 수 있습니다.',
      true,
    ],
    [
      '맞춤형 회원가입 이벤트',
      false,
      false,
      true,
      true,
      '사용자가 회원가입 후 웰컴 이메일 등 다양한 이벤트를 설정할 수 있습니다.',
      true,
    ],
    [
      '맞춤형 회원가입 설문',
      false,
      false,
      true,
      true,
      '회원가입한 사용자에게 궁금한 사항이나 받아야 할 정보들을 설정할 수 있습니다.',
      true,
    ],
    [
      '사용자 통계',
      false,
      true,
      true,
      true,
      'DAU, 전환율, 최적화된 배치 등을 통계와 함께 알려드려요. 팀과 함께 데이터 기반의 의사결정을 내리기 위한 최고의 솔루션을 제공합니다.',
      true,
    ],
    [
      '맞춤형 로그인 화면',
      false,
      false,
      true,
      true,
      '로그인 화면을 커스터마이즈하여 사용자의 로그인 경험을 개선할 수 있습니다.',
      false,
    ],
    [
      '사용자 권한 부여',
      false,
      false,
      true,
      true,
      '사용자 권한을 부여하여 서비스에 적합한 역할을 설정할 수 있습니다.',
      false,
    ],
  ],
  security: [
    [
      'SSO',
      false,
      true,
      true,
      true,
      'Single Sign-On을 적용하여 프로젝트에 가입하는 사용자들에게 편리함과 보안성을 제공합니다.',
      false,
    ],
    [
      'Captcha',
      false,
      false,
      true,
      true,
      '별도의 주민번호 또는 개인정보 수집 없이 악성 봇이 접근하는걸 막을 수 있어요.',
      false,
    ],
    [
      'IP Bot 공격 방지',
      false,
      false,
      false,
      true,
      '악의적인 IP를 탐지하고 악성 봇 공격으로부터 안전하게 보호하세요.',
      false,
    ],
    [
      '중복 로그인 방지',
      false,
      false,
      false,
      true,
      '서비스 정책에 맞게 중복 로그인 방지를 설정하실 수 있습니다.',
      false,
    ],
    [
      '본인 인증',
      false,
      false,
      false,
      true,
      'SMS, OTP 등 본인 인증 수단이 추가될수록 해커의 계정 탈취로부터 안전해집니다.',
      false,
    ],
    [
      'Multifactor 인증',
      false,
      false,
      false,
      true,
      '다양한 인증 방법을 함께 적용하여 서비스의 보안성을 향상시켜보세요.',
      false,
    ],
    [
      'Cloudflare 사용',
      false,
      false,
      false,
      true,
      'Cloudflare를 연동하여 공용 네트워크에 노출된 리소스를 안전하게 관리해보세요.',
      false,
    ],
  ],
  support: [
    [
      'API 문서',
      true,
      true,
      true,
      true,
      '쉽게 따라할 수 있는 공식 API 문서를 제공합니다.',
      true,
    ],
    [
      '개발자 커뮤니티',
      true,
      true,
      true,
      true,
      '자유롭게 Furo에 대해 질의하고 건의할 수 있는 개발자 포럼이 운영됩니다.',
      true,
    ],
    [
      'DB 마이그레이션',
      false,
      true,
      true,
      true,
      '기존 사용자 데이터를 쉽고 빠르게 Furo로 마이그레이션 해드립니다.',
      true,
    ],
    [
      '전용 Slack 채널',
      false,
      false,
      true,
      true,
      '실시간으로 문의하실 수 있는 Slack 채널이 개설되어 보다 빠르게 문제를 해결할 수 있습니다.',
      true,
    ],
    [
      'Furo 로고 제거',
      false,
      false,
      true,
      true,
      'Furo 브랜드 로고를 제거하여 온전한 로그인 서비스 페이지를 구성해보세요.',
      true,
    ],
    [
      '서비스 수준 협약(SLA) 체결',
      false,
      false,
      false,
      true,
      'Service Level Agreement(서비스 수준 협약). 서비스 수준을 보장하기 위한 계약입니다.',
      true,
    ],
  ],
};

const plans = [
  {
    tier: 'Developer',
    price: { monthly: { net: '₩0' }, yearly: { net: '₩0' } },
    discription: '사이드 프로젝트를 위한 플랜',
    features: [
      '5,000 MAU',
      '제한 없는 로그인 방식',
      '로그인 무제한',
      '프로젝트 무제한',
      // '웹훅 1가지 제공',
      // '트래픽 관리',
    ],
    action: '플랜 시작',
  },
  // {
  //   tier: 'Essential',
  //   price: { monthly: { net: '₩40,000' }, yearly: { net: '₩40,000' } },
  //   discription: '빠른 프로덕트 개발을 위한 플랜',
  //   features: [
  //     '5,000 MAU',
  //     '로그인 방식 최대 2가지 제공',
  //     '로그인 무제한',
  //     '프로젝트 무제한',
  //     // '웹훅 1가지 제공',
  //     // '트래픽 관리',
  //   ],
  //   action: '2개월 무료 체험',
  // },
  {
    tier: 'Business',
    price: {
      monthly: { net: '₩400,000' },
      yearly: { net: '₩400,000', discount: '6.25%', sale: '₩375,000' },
    },
    discription: '초기 스타트업을 위한 플랜',
    features: [
      '50,000 MAU',
      '전담 개발팀 지원',
      '본인 인증 월 1만 건 제공',
      '웹훅·동작·규칙 5가지 제공',
      // '회원가입 환영 동작·설문 3가지 제공',
      // 'SSO 제공',
      // '사용자 통계',
      // 'Captcha',
      // '최신 개인정보보호법 관리',
      // 'DB 마이그레이션',
      // '맞춤형 로그인 화면',
      // '로그인 세션 유지',
    ],
    action: '플랜 시작',
  },
  {
    tier: 'Enterprise',
    price: {
      // monthly: { net: '₩4,000,000' },
      // yearly: { net: '₩48,000,000', discount: '6.25%', sale: '₩45,000,000' },
      monthly: { net: '견적 문의' },
      yearly: { net: '견적 문의' },
    },
    discription: '향상된 보안과 성능을 위한 플랜',
    features: [
      '500,000 MAU',
      '본인 인증 월 10만 건 제공',
      '웹훅·동작·규칙 10가지 제공',
      '맞춤형 회원가입 환영 동작',
      // '맞춤형 회원가입 설문',
      // 'Multifactor 인증',
      // '사용자 권한 부여',
      // '도스 공격 방지',
      // 'Cloudflare 사용',
      // '싱글 세션을 통한 이중 로그인 방지',
      // '로그인 이력 관리',
      // '서비스 수준 협약(SLA) 체결',
      // '1:1 슬랙 지원',
      // 'Furo 브랜딩 제거',
    ],
    action: '견적 문의',
  },
];

export default { features, plans };
