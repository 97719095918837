import { FormOutlined } from '@ant-design/icons';

const presets = [
  {
    name: 'slack',
    label: 'Slack 스타일',
    description: `사용자가 매직링크와\n패스워드 로그인을\n선택할 수 있는 스타일`,
    imgSrc: 'https://cdn-icons-png.flaticon.com/512/2111/2111615.png',
    setting: {
      allow_otp: true,
      show_pw_prompt: true,
      use_existing_password: false,
      allow_ip_detection: false,
    },
  },
  {
    name: 'notion',
    label: 'Notion 스타일',
    description: `사용자가 패스워드를\n설정했을 경우에만\n패스워드 로그인을\n선택할 수 있는 스타일`,
    imgSrc: '../../images/notion.svg',
    setting: {
      allow_otp: true,
      show_pw_prompt: false,
      use_existing_password: true,
      allow_ip_detection: false,
    },
  },
  {
    name: 'custom',
    label: 'Custom',
    description: `우선 한번 둘러보고\n필요한 기능만\n골라서 사용할래요.`,
    icon: FormOutlined,
    setting: {},
  },
];

export default presets;
